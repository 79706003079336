import { gnp_estatus } from "../../constants/gnp_cons";
import {
  URL_ASISTENCIA_DESTINO,
  URL_BASE_ASISTENCIAS,
  URL_CABINA_ASIGNACION,
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, getFile, post } from "../../utils/fetch";
import { types } from "../types/types";
import { startUpdateServicioGnp } from "./gnpApiActions";
import { notification } from "./notificationActions";
import { hdiTipoFechas, putNotification } from "./hdiActions";
import { getDistance } from "../../utils/getDistance";

//////////
export const startLoadAsistencia = (asistenciaID, mostrarLoading = true) => {
  return async (dispatch) => {
    if (mostrarLoading) dispatch(setLoadingDetalle(true));

    const result = await get(
      `${URL_BASE_ASISTENCIAS}/GetAsistencia/${asistenciaID}`
    );
    if (result.code === 0) {
      dispatch(storeAsistencia(result.data));
      dispatch(startLoadSucursalesByServicio(result.data.servicioID));
      dispatch(startGetHistorial(asistenciaID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexión - Get asistencia",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setLoadingDetalle(false));
  };
};

const setLoadingDetalle = (estado) => ({
  type: types.setLoadingDetalle,
  payload: estado,
});
export const storeAsistencia = (data) => ({
  type: types.storeAsistencia,
  payload: data,
});
export const setFolderPagina = (pagina) => ({
  type: types.setFolderPagina,
  payload: pagina,
});

export const clearProveedorCostos = () => ({
  type: types.clearProveedorCostos,
  payload: null,
});
///

export const startLoadSucursalesByServicio = (servicioID) => {
  return async (dispatch) => {
    const result = await get(
      `${URL_BASE_ASISTENCIAS}/GetSucursalesByServico/${servicioID}`
    );
    if (result.code === 0) {
      dispatch(storeSucursalesByServicio(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - GetSucursalesByServico",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeSucursalesByServicio = (sucursales) => ({
  type: types.storeSucursalesByServicio,
  payload: sucursales,
});

/////////////////////////////////

export const startEstablecerCita = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/EstablecerCita`;

    const result = await post(url, body);
    console.log(body);

    if (result.code === 0) {
      dispatch(startLoadAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Establecer cita",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const startQuitarCita = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/QuitarCita/${asistenciaID}`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(startLoadAsistencia(asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Establecer cita",
            `${result.error}`
          )
        )
      );
    }
  };
};

/////////////////////////////////
export const startSetStep = () => {
  return async (dispatch, getState) => {
    const { asistenciaID, folioGnp, esGnpApi, estatusID } =
      getState().detalleReducer.asistencia;
    const url = `${URL_BASE_ASISTENCIAS}/AvanzaStep/${asistenciaID}`;

    const result = await post(url);
    if (result.code === 0) {
      dispatch(startLoadAsistencia(asistenciaID, false));

      if (esGnpApi) {
        if (estatusID === 3)
          // 3=proveedor asignado, enviamos arribo
          dispatch(
            startUpdateServicioGnp({
              folioAsignacion: folioGnp,
              estatusActual: gnp_estatus.ARRIBO.clave,
            })
          );
        if (estatusID === 5)
          // 5=contacto asignado, enviamos termiando
          dispatch(
            startUpdateServicioGnp({
              folioAsignacion: folioGnp,
              estatusActual: gnp_estatus.TERMINADO.clave,
            })
          );
      }
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Avanza step",
            `${result.error}`
          )
        )
      );
    }
  };
};

////
export const startPostProveedor = (body) => {
  return async (dispatch, getState) => {
    //
    const { nombre, usuarioID } = getState().userReducer.user;
    const { proveedorSucursalID } = getState().detalleReducer.proveedorCostos;
    // TODO:  post hdi-proveedor
    // const url = `${URL_BASE_ASISTENCIAS}/AsignaProveedor`;
    const url = `${URL_CABINA_ASIGNACION}/AsignaProveedor2`;
    const result = await post(url, {
      ...body,
      proveedorSucursalID,
      usuarioID,
      usuario: nombre,
    });
    //
    if (result.code === 0) {

      dispatch(startLoadAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));

      if (body.estaAsignado && body.esGnpApi)
        dispatch(
          startUpdateServicioGnp({
            folioAsignacion: body.folioGnp,
            estatusActual: gnp_estatus.EN_ATENCION.clave,
          })
        );
    } else {
      dispatch(notification(composeErrorNotification(`${result.error}`)));
      dispatch(startLoadAsistencia(body.asistenciaID, false));
    }
  };
};

/// actualiza a gnp

export const startActualizaServicioGnp = (estatusActual) => {
  return async (dispatch, getState) => {
    const { folioGnp, tiempoArribo } = getState().detalleReducer;

    const url = `${URL_BASE_ASISTENCIAS}/AsignaProveedor`;
    const body = {
      estatusActual,
      folioGnp,
      tiempoArribo,
    };

    const result = await post(url, body);

    //
    if (result.code === 0) {
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion startActualizaServicioGnp"
          )
        )
      );
    }
  };
};

/////////////////////////////////////////////////////

export const startGetAsistenciasByValidador1 = () => {
  return async (dispatch, getState) => {
    const { validador1 } = getState().detalleReducer.asistencia.asegurado;
    const url = `${URL_BASE_ASISTENCIAS}/GetAsistenciasByValidador1/${validador1}`;

    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeAsistenciasByValidador1(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Avanza step",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeAsistenciasByValidador1 = (asistencias) => ({
  type: types.storeAsistenciasByValidador1,
  payload: asistencias,
});
/////////////////////////////////////////////////////

export const startPostConcluirServicio = (body) => {
  return async (dispatch) => {
    //
    console.log(body);
    const url = `${URL_BASE_ASISTENCIAS}/ConcluirServicio`;
    const result = await post(url, body);
    //
    if (result.code === 0) {
      dispatch(startLoadAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
      // Concluir el servicio para HDI (lógica de hdi en la función)
      if (body.estatusFinal === 2 || body.estatusFinal === 3) {
        dispatch(putNotification(hdiTipoFechas.fechaTermino));
      }
      // Cancelar el servicio para HDI (lógica de hdi en la función)
      if (body.estatusFinal === 4 || body.estatusFinal === 5) {
        dispatch(putNotification(hdiTipoFechas.fechaCancelado));
      }

      if (body.esGnpApi) {
        if (body.estatusFinal === 2 || body.estatusFinal === 3)
          // concluido y otorgado
          dispatch(
            startUpdateServicioGnp({
              folioAsignacion: body.folioGnp,
              estatusActual: gnp_estatus.TERMINADO.clave,
            })
          );

        if (body.estatusFinal === 4)
          // Cancelado con costo
          dispatch(
            startUpdateServicioGnp({
              folioAsignacion: body.folioGnp,
              estatusActual: gnp_estatus.NO_LOCALZIADO.clave,
            })
          );

        if (body.estatusFinal === 5)
          // Cancelado sin costo
          dispatch(
            startUpdateServicioGnp({
              folioAsignacion: body.folioGnp,
              estatusActual: gnp_estatus.CANCELADO.clave,
            })
          );
      }
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - post concluir",
            `${result.error}`
          )
        )
      );
    }
  };
};

//////////////////////////////////

// export const startPostConcluirServicio = (body) => {
//   return async (dispatch) => {
//     //
//     const url = `${URL_BASE_ASISTENCIAS}/ConcluirServicio`;
//     const result = await post(url, body);
//     //
//     if (result.code === 0) {
//       dispatch(startLoadAsistencia(body.asistenciaID, false));
//       dispatch(notification(composeSuccessNotification(result.data)));
//     } else {
//       dispatch(
//         notification(
//           composeErrorNotification(
//             "Error de conexion - post concluir",
//             `${result.error}`
//           )
//         )
//       );
//     }
//   };
// };
//////////////////////////////////

export const startUpdateFecha = (body) => {
  return async (dispatch) => {
    //
    const url = `${URL_BASE_ASISTENCIAS}/UpdateFecha`;
    const result = await post(url, body);
    //
    if (result.code === 0) {
      dispatch(startLoadAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error", `${result.error}`))
      );
    }
  };
};

///////////////

export const startPostDestino = (body) => {
  return async (dispatch, getState) => {
    //
   // const url = `${URL_BASE_ASISTENCIAS}/PostDestino`;
    const url = `${URL_ASISTENCIA_DESTINO}/PostDestino`;
    const result = await post(url, body);

    //
    if (result.code === 0) {
      dispatch(startLoadAsistencia(body.asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));

      return result;
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Mapa destino",
            `${result.error}`
          )
        )
      );
    }
  };
};

////
export const setProveedorGeos = (geo) => ({
  type: types.setProveedorGeos,
  payload: geo,
});

export const clearProveedorGeos = () => ({
  type: types.clearProveedorGeos,
  payload: null,
});

export const getProveedorCostos = (sucursaLSelectedFromMap) => {
  return async (dispatch, getState) => {
    let sucursalFinalID = 0;
    const { servicioID, proveedorSucursalID } =
      getState().detalleReducer.asistencia;

    if (!sucursaLSelectedFromMap) sucursalFinalID = proveedorSucursalID;
    //
    // viene el state si ya se escogió proveedor
    else sucursalFinalID = sucursaLSelectedFromMap; // viene cuando picas en mapa

    // si no hay proveedor, a penas se va a buscar, no hay que hacer nada
    if (!sucursalFinalID) {
      dispatch(clearProveedorCostos());
      return;
    }

    //
    const body = {
      servicioID,
      proveedorSucursalID: sucursalFinalID,
    };

    const url = `${URL_BASE_ASISTENCIAS}/GetSucursalCostos`;
    const result = await post(url, body);

    //
    if (result.code === 0) {
      dispatch(storeProveedorCostos(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get costos",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeProveedorCostos = (data) => ({
  type: types.storeProveedorCostos,
  payload: data,
});

////////
export const startLoadHistorialAsignacion = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetHistorialAsignacion/${asistenciaID}`;
    const result = await get(url);

    //
    if (result.code === 0) {
      dispatch(storeHistorialAsignacion(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get historial asignacion",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeHistorialAsignacion = (data) => ({
  type: types.storeHistorialAsignacion,
  payload: data,
});

//////////////////////////////////

export const startPostComentarios = (body) => {
  return async (dispatch, getState) => {
    const { usuarioID, nombre } = getState().userReducer.user;
    const url = `${URL_BASE_ASISTENCIAS}/PostAsistenciaHistorial`;
    const result = await post(url, {
      ...body,
      usuarioID,
      usuarioNombre: nombre,
    });
    console.log(result.data);
    //
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetHistorial(body.asistenciaID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get costos",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const startGetHistorial = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetAsistenciaHistorial/${asistenciaID}`;
    const result = await get(url);

    //
    if (result.code === 0) {
      dispatch(storeAsistenciaHistorial(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get historial asignacion",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeAsistenciaHistorial = (historial) => ({
  type: types.storeAsistenciaHistorial,
  payload: historial,
});

//////////////

export const startBloquearServicio = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/BloquearServicio`;
    const result = await post(url, body);

    //
    if (result.code === 0) {
      console.log("bloquedo");
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Post bloqueo",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const startDesBloquearServicio = (asistenciaID) => {
  return async (dispatch) => {
    const body = {
      usuario: "",
      asistenciaID,
    };
    const url = `${URL_BASE_ASISTENCIAS}/BloquearServicio`;
    const result = await post(url, body);

    //
    if (result.code === 0) {
      console.log("desbloqueado");
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion - Post bloqueo")
        )
      );
    }
  };
};

//////////////  Files

export const startGetAsistenciaFiles = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetDocumentos/${asistenciaID}`;
    const result = await get(url);

    //
    if (result.code === 0) {
      dispatch(storeAsistenciaFiles(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion - Get documentos")
        )
      );
    }
  };
};

const storeAsistenciaFiles = (files) => ({
  type: types.storeAsistenciaFiles,
  payload: files,
});

///

export const startGetAsistenciaFile = (id, tipo, nombre) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetDocumento/${id}/${tipo}`;
    const nombreFinal = `${nombre}`;

    const result = await getFile(url, nombreFinal);

    if (result.code === -1) {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion - Download documento A")
        )
      );
    }
  };
};

///////////////

export const startDownloadPlacas = (asistenciaID, nombrePlacas) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetDetalleDocumento/${asistenciaID}/${nombrePlacas}`;

    const result = await getFile(url, nombrePlacas);

    if (result.code === -1) {
      dispatch(notification(composeErrorNotification("GetDetalleDocumento")));
    }
  };
};

/////////////
export const startValidaProveedor = (asistenciaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;

    const url = `${URL_BASE_ASISTENCIAS}/ValidaProveedor`;

    const result = await post(url, { usuarioID, asistenciaID });

    if (result.code === 0) {
      dispatch(startLoadAsistencia(asistenciaID, false));
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("ValidaProveedor", `${result.error}`)
        )
      );
    }
  };
};

export const getDistanceBetweenPlaces = (origin, destiny) => {
  return async (dispatch) => {
  //  const result = await getDistance(origin, destiny);

  //  dispatch(actionSaveDistance(result));
  };
};

const actionSaveDistance = (payload) => ({
  type: types.saveDistance,
  payload,
});
