import {
  BankOutlined,
  ClockCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Tooltip } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useDispatch } from "react-redux";
import { startPostAceptarPropuesta } from "../../../context/actions/serviciosPropuestos";

export const ItemsPropuesta = ({ data }) => {
  const dispatch = useDispatch();

  //
  const submit = () => {
    dispatch(
      startPostAceptarPropuesta({
        id: data.id,
        comentarios: "Servicio aceptado por subasta carretera",
      })
    );
  };

  //
  return (
    <Row
      gutter={10}
      style={{
        marginBottom: 5,
        borderBottom: "1px dotted #ccc",
        paddingBottom: 3,
      }}
    >
      <Col span={1}>
        <BankOutlined style={{ fontSize: 18, color: "orange" }} />
      </Col>
      <Col span={7}>{data?.proveedorNombre}</Col>
      <Col span={1}>
        <ClockCircleOutlined style={{ fontSize: 18, color: "orange" }} />
      </Col>
      <Col span={5}>{data?.tiempoLlegadaPropuesto} minutos</Col>
      <Col span={1}>
      <Tooltip title={data?.descripcionCostoPropuesto}>

        <DollarOutlined style={{ fontSize: 18, color: "orange" }} />
      </Tooltip>
      </Col>
      <Col span={5}>{formatCurrency(data?.costoPropuesto)}</Col>
      <Col span={3}>
        <Popconfirm
          title="Confirmas asignar este servivcio?"
          onConfirm={submit}
          onCancel={() => {}}
          okText="Si"
          cancelText="No"
        >
          <Button type="primary" size="small">
            Asignar
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
};
